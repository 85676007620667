<template>
  <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
    <template v-if="internetConnection">
        <LoaderPage v-if="isLoading" />
        <template v-else>
            <!-- Create template button -->
            <div 
                v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])"
                class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-between"
            >
                <button type="button" class="uk-button uk-button-default" @click="goBack">
                    <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
                    <span>Back</span>
                </button>
                <button 
                    type="button" 
                    class="uk-button uk-button-primary uk-border-rounded" 
                    @click="showCreateOrEditTemplateModalBox({ type: 'create', id: null, name: null, questions: [] })"
                >Create New Question Tempate</button>
            </div>
            <!-- End create template button -->

            <!-- Template list -->
            <div v-if="additionalQuestionTemplateData.length > 0" class="uk-margin-top uk-card uk-card-default">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-striped uk-table-middle">
                        <thead>
                            <tr>
                                <th class="uk-text-center uk-table-shrink">No</th>
                                <th class="uk-text-left uk-text-nowrap">Template Name</th>
                                <th 
                                    v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])"
                                    class="uk-text-center uk-text-nowrap uk-width-small"
                                >Actions</th>
                            </tr>
                        </thead>
                        <LoadingTable v-if="tableLoading" :colspan="3" />
                        <template v-else>
                            <tbody v-if="additionalQuestionTemplateData.length > 0">
                                <tr v-for="(template, index) in additionalQuestionTemplateData" :key="template && template._id || index">
                                    <td class="uk-text-center uk-table-shrink">{{ index + 1 || 0 }}</td>
                                    <td class="uk-text-left uk-text-nowrap">{{ template && template.name || '-' }}</td>
                                    <td 
                                        v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])"
                                        class="uk-text-center uk-text-nowrap uk-width-small"
                                    >
                                        <button type="button" class="uk-button uk-button-default uk-button-small">Actions</button>
                                        <div uk-dropdown="mode: click">
                                            <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                <li @click="showCreateOrEditTemplateModalBox({
                                                    type: 'edit',
                                                    id: template && template._id || null,
                                                    name: template && template.name || null,
                                                    questions: template && Array.isArray(template.questions) && template.questions.length > 0 ? template.questions : []
                                                })"><a disabled>Edit</a></li>
                                                <li class="uk-nav-divider"></li>
                                                <li @click="showConfirmationDeleteTemplateModalBox({
                                                    id: template && template._id || null,
                                                    name: template && template.name || null
                                                })"><a disabled>Delete</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="3" />
                        </template>
                    </table>
                </div>
            </div>
            <EmptyData 
                v-else
                message1="No Template have been created yet."
                message2="Please create a template first by clicking the 'Create New Question"
                message3="Template' button."
            />
            <!-- End end template list -->

            <!-- Create or edit template modal box -->
            <div
                id="create-or-edit-template-modal-box" 
                class="uk-flex-top create-or-edit-template-modal-box" 
                uk-modal 
                esc-close="false" 
                bg-close="false"
            >
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">{{ modalBoxTitle }}</h2>
                    </div>
                    <form @submit.prevent="createOrEditAdditionalQuestionTemplate">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="templateName" class="uk-form-label">Template Name <span class="uk-text-danger">*</span></label>
                                    <div class="margin-top-5px">
                                        <input 
                                            id="templateName" 
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            maxlength="60"
                                            placeholder="Type template name here..."
                                            data-vv-name="templateName"
                                            data-vv-scope="selectedTemplate"
                                            v-validate="{ 
                                                required: true,
                                                max: 60
                                            }"
                                            v-model="selectedTemplate.name"
                                        />
                                        <div 
                                            class="uk-width-1-1 uk-flex uk-flex-middle"
                                            :class="[errors.has('selectedTemplate.templateName') ? 'uk-flex-between' : 'uk-flex-right']"
                                        >
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedTemplate.templateName')">
                                                {{ errors.first('selectedTemplate.templateName') }}
                                            </span>
                                            <p class="uk-text-small uk-margin-remove uk-text-right">{{ selectedTemplate.name.length }}/60</p>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="selectedTemplate.questions.length > 0" class="uk-width-1-1">
                                    <div 
                                        v-for="(question, indexQuestion) in selectedTemplate.questions"
                                        :key="question && question._id || indexQuestion"
                                        class="uk-grid-small" 
                                        uk-grid
                                    >
                                        <div class="uk-width-1-2">
                                            <label class="uk-form-label">Question {{ indexQuestion + 1 }}</label>
                                            <input 
                                                type="text" 
                                                class="uk-input uk-border-rounded"
                                                placeholder="Type question here..."
                                                v-model="question.question"
                                            />

                                            <div
                                                class="uk-width-1-1 uk-flex"
                                                :class="questionErrors[indexQuestion] && questionErrors[indexQuestion].question ? 'uk-flex-between' : 'uk-flex-right'"
                                            >
                                                <span v-if="questionErrors[indexQuestion] && questionErrors[indexQuestion].question" class="uk-text-small uk-text-danger">
                                                    {{ questionErrors[indexQuestion].question }}
                                                </span>
                                                <span class="uk-text-small">{{ question.question.length || 0 }}/200</span>
                                            </div>
                                        </div>
                                        <div class="uk-width-1-2">
                                            <label class="uk-form-label">Answer Type</label>
                                            <select class="uk-select uk-border-rounded" v-model="question.type" @change="(question.options = [])">
                                                <option 
                                                    v-for="(type, indexType) in answerType"
                                                    :key="indexType"
                                                    :value="type.value"
                                                >{{ type.label }}</option>
                                            </select>
                                        </div>
                                        <div v-if="question.type === questionType.short_answer" class="uk-display-block uk-width-1-2">
                                            <div class="uk-width-1-1">
                                                <textarea
                                                    name="description"
                                                    class="uk-border-rounded uk-textarea"
                                                    style="line-height: 25px;"
                                                    v-model="question.description"
                                                    placeholder="Type description here (optional)"
                                                    :maxlength="400"
                                                    @input="descriptionChange"
                                                />
                                                <div class="uk-width-1-1 uk-flex uk-flex-right" style="margin-bottom: 15px">
                                                    <span class="uk-text-small uk-text-right">{{ question.description && question.description.length || 0 }}/400</span>
                                                </div>
                                            </div>
                                            <input type="text" class="uk-width-1-1 uk-input uk-border-rounded" placeholder="Short answer" disabled />
                                        </div>
                                        <div v-else-if="question.type === questionType.single_choice" class="uk-width-1-1">
                                            <div class="uk-grid-small" uk-grid>
                                                <div class="uk-width-1-1">
                                                    <label class="uk-form-label">Options</label>
                                                    <div v-if="questionErrors[indexQuestion] && questionErrors[indexQuestion].options" class="uk-width-1-1">
                                                        <span class="uk-text-small uk-text-danger">
                                                            {{ questionErrors[indexQuestion].options }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="question.options.length > 0" class="uk-width-1-1">
                                                    <div class="uk-grid-small" uk-grid>
                                                        <div v-for="(_, indexOption) in question.options" :key="indexOption" class="uk-width-1-1">
                                                            <div class="uk-flex uk-flex-left uk-flex-middle">
                                                                <input type="radio" class="uk-radio uk-margin-small-right" disabled />
                                                                <input 
                                                                    type="text" 
                                                                    class="uk-input uk-border-rounded uk-margin-small-right uk-width-1-2" 
                                                                    :placeholder="`Option ${indexOption + 1}`" 
                                                                    v-model="question.options[indexOption]" 
                                                                />
                                                                <button 
                                                                    type="button" 
                                                                    class="uk-flex uk-flex-center uk-flex-middle delete-option-button" 
                                                                    @click="deleteOptions(indexQuestion, indexOption)"
                                                                >
                                                                    <img 
                                                                        :src="`${images}/trash-red.svg`" 
                                                                        alt="Trash icon"
                                                                        loading="lazy"
                                                                        class="delete-option-button-icon"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="uk-width-1-1">
                                                    <button type="button" class="uk-flex uk-flex-left uk-flex-middle add-option-button" @click="addOptions(indexQuestion)">
                                                        <img 
                                                            :src="`${images}/add-blue.svg`" 
                                                            alt="Add icon"
                                                            loading="lazy"
                                                            class="uk-margin-small-right" 
                                                        />
                                                        <p class="uk-margin-remove">Add option</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="question.type === questionType.multi_choices" class="uk-width-1-1">
                                            <div class="uk-grid-small" uk-grid>
                                                <div class="uk-width-1-1">
                                                    <label class="uk-form-label">Options</label>
                                                    <div v-if="questionErrors[indexQuestion] && questionErrors[indexQuestion].options" class="uk-width-1-1">
                                                        <span class="uk-text-small uk-text-danger">
                                                            {{ questionErrors[indexQuestion].options }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="question.options.length > 0" class="uk-width-1-1">
                                                    <div class="uk-grid-small" uk-grid>
                                                        <div v-for="(_, indexOption) in question.options" :key="indexOption" class="uk-width-1-1">
                                                            <div class="uk-flex uk-flex-left uk-flex-middle">
                                                                <input type="checkbox" class="uk-checkbox uk-border-rounded uk-margin-small-right" disabled />
                                                                <input 
                                                                    type="text" 
                                                                    class="uk-input uk-border-rounded uk-margin-small-right uk-width-1-2" 
                                                                    :placeholder="`Option ${indexOption + 1}`" 
                                                                    v-model="question.options[indexOption]" 
                                                                />
                                                                <button 
                                                                    type="button" 
                                                                    class="uk-flex uk-flex-center uk-flex-middle delete-option-button" 
                                                                    @click="deleteOptions(indexQuestion, indexOption)"
                                                                >
                                                                    <img 
                                                                        :src="`${images}/trash-red.svg`" 
                                                                        alt="Trash icon"
                                                                        loading="lazy"
                                                                        class="delete-option-button-icon"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="uk-width-1-1">
                                                    <button type="button" class="uk-flex uk-flex-left uk-flex-middle add-option-button" @click="addOptions(indexQuestion)">
                                                        <img 
                                                            :src="`${images}/add-blue.svg`" 
                                                            alt="Add icon"
                                                            loading="lazy"
                                                            class="uk-margin-small-right" 
                                                        />
                                                        <p class="uk-margin-remove">Add option</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="uk-width-1-2">
                                            <div class="uk-position-relative">
                                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                                <datetime
                                                    input-class="uk-input uk-border-rounded"
                                                    type="date"
                                                    placeholder="DD/MM/YYYY"
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div class="uk-width-1-1">
                                            <div class="uk-flex uk-flex-middle uk-flex-between">
                                                <label><input class="uk-checkbox uk-border-rounded" type="checkbox" v-model="question.required"> Required</label>
                                                <button 
                                                    type="button"
                                                    class="uk-flex uk-flex-center uk-flex-middle uk-button uk-button-small delete-question-button"
                                                    @click="deleteQuestion(indexQuestion)"
                                                >
                                                    <img 
                                                        :src="`${images}/trash-red.svg`" 
                                                        alt="Trash icon"
                                                        loading="lazy" 
                                                    />
                                                    <p class="uk-margin-remove">Delete</p>
                                                </button>
                                            </div>
                                            <hr class="uk-margin-small" />
                                        </div>
                                    </div>
                                </template>
                                <div class="uk-width-1-1">
                                    <button 
                                        type="button"
                                        class="uk-button uk-button-default uk-border-rounded uk-width-1-1 uk-flex uk-flex-center uk-flex-middle add-question-button"
                                        @click="addQuestion"
                                    >
                                        <img 
                                            :src="`${images}/add-circle-blue.svg`" 
                                            alt="Add circle blue icon"
                                            loading="lazy" 
                                            class="uk-margin-small-right"
                                        />
                                        <p class="uk-margin-remove">Add Question</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <LoaderButton v-if="createOrEditTemplateButtonLoading" />
                            <button 
                                v-else
                                type="submit" 
                                class="uk-button"
                                :class="[disabledCreateOrEditAdditionalQuestionTemplateButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledCreateOrEditAdditionalQuestionTemplateButton"
                            >Save</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End create or edit template modal box -->

            <!-- Confirmation delete template modal box -->
            <div
                id="confirmation-delete-template-modal-box"
                class="uk-flex-top confirmation-delete-template-modal-box"
                uk-modal
                esc-close="false"
                bg-close="false"
            >
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                        <div class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-left">
                            <img 
                                :src="`${images}/error-40-outline.svg`"
                                alt=""
                                loading="lazy"
                                class="uk-margin-small-right"
                            />
                            <h2 class="uk-modal-title uk-margin-remove">Delete Template</h2>
                        </div>
                    </div>
                    <div class="uk-modal-body">
                        <p class="uk-margin-remove">Are you sure you want to delete this <span class="uk-text-bold">'{{ selectedTemplate.name || '-' }}'</span> template?</p>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <LoaderButton v-if="deleteTemplateButtonLoading" />
                        <button 
                            v-else
                            type="button" 
                            class="uk-button uk-button-primary"
                            @click="deleteAdditionalQuestionTemplate"
                        >Yes, I'm Sure</button>
                    </div>
                </div>
            </div>
            <!-- End confirmation delete template modal box -->
        </template>
    </template>
    <NoInternetConnection v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    // notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'DetailAdditionalQuestion',
    components: {
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        EmptyData: () => import('@/components/globals/AdditionalQuestions/EmptyData'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        Datetime,
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            createOrEditTemplateButtonLoading: false,
            deleteTemplateButtonLoading: false,
            additionalQuestionTemplateData: [],
            meta: {
                limit: 10,
                page: 1
            },
            selectedTemplate: {
                id: '',
                name: '',
                questions: []
            },
            answerType: [
                {
                    label: "Short Answer",
                    value: 1
                },
                {
                    label: "Single Choice",
                    value: 2
                },
                {
                    label: "Multiple Choice",
                    value: 3
                },
                {
                    label: "Date",
                    value: 4,
                }
            ],
            modalBoxType: 'create',
            modalBoxTitle: 'Create Question(s) Template',
            questionErrors: {}
        };
    },
    watch: {
        'selectedTemplate.questions': {
            deep: true,
            handler() {
                this.questionErrors = {};
                this.selectedTemplate.questions.forEach((question, indexQuestion) => this.validateQuestion(question, indexQuestion));
            }
        },
    },
    computed: {
        disabledCreateOrEditAdditionalQuestionTemplateButton() {
            let disabled = true;

            if (this.modalBoxType === 'create') {
                if (
                    this.selectedTemplate.name === ''
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.modalBoxType === 'edit') {
                if (
                    this.selectedTemplate.id === '' ||
                    this.selectedTemplate.name === '' ||
                    !this.isEmptyObject(this.questionErrors)
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            }

            return disabled;
        },
        user_cred() {
            return getUserLogin();
        },
        questionType() {
            return {
                short_answer: 1,
                single_choice: 2,
                multi_choices: 3,
                date: 4
            };
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-template-modal-box')) window.UIkit.modal('.create-or-edit-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-template-modal-box')) window.UIkit.modal('.confirmation-delete-template-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.setAdditionalQuestionTemplateData();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            createTemplate: 'additionalQuestionTemplateV4/createTemplate',
            getTemplateList: 'additionalQuestionTemplateV4/getTemplateList',
            editTemplate: 'additionalQuestionTemplateV4/editTemplate',
            deleteTemplate: 'additionalQuestionTemplateV4/deleteTemplate',
        }),
        async setAdditionalQuestionTemplateData() {
            try {
                const response = await this.getTemplateList({ 
                    categoryId: this.$route.params.id,
                    ...this.meta
                });

                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result || [];
                    this.additionalQuestionTemplateData = result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createAdditionalQuestionTemplate() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;

                const validate = await this.$validator.validate('selectedTemplate.*');
                if (!validate || this.$validator.errors.any('selectedTemplate')) return;

                this.createOrEditTemplateButtonLoading = true;

                const payload = {
                    name: '',
                    questions: []
                };

                if (this.selectedTemplate.name) {
                    payload.name = this.selectedTemplate.name;
                } else {
                    payload.name = '';
                }

                if (this.selectedTemplate.questions.length > 0) {
                    payload.questions = this.selectedTemplate.questions;
                } else {
                    payload.questions = [];
                }

                const response = await this.createTemplate({
                    categoryId: this.$route.params.id,
                    ...payload
                });

                if (response && response.data && response.data.status === 'OK' && response.data.result && response.data.result._id) {
                    notificationSuccess('Template created successfully');
                    this.tableLoading = true;
                    await this.setAdditionalQuestionTemplateData();
                    await window.UIkit.modal('#create-or-edit-template-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditTemplateButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async editAdditionalQuestionTemplate() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;

                const validate = await this.$validator.validate('selectedTemplate.*');
                if (!validate || this.$validator.errors.any('selectedTemplate')) return;

                this.createOrEditTemplateButtonLoading = true;

                const payload = {
                    name: '',
                    questions: []
                };

                if (this.selectedTemplate.name) {
                    payload.name = this.selectedTemplate.name;
                } else {
                    payload.name = '';
                }

                if (this.selectedTemplate.questions.length > 0) {
                    payload.questions = this.selectedTemplate.questions;
                } else {
                    payload.questions = [];
                }

                const response = await this.editTemplate({
                    categoryId: this.$route.params.id,
                    id: this.selectedTemplate.id,
                    ...payload
                });

                if (response && response.data && response.data.status === 'OK' && response.data.result && response.data.result._id) {
                    notificationSuccess('Template updated successfully');
                    this.tableLoading = true;
                    await this.setAdditionalQuestionTemplateData();
                    await window.UIkit.modal('#create-or-edit-template-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditTemplateButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async deleteAdditionalQuestionTemplate() {
            try {
                this.deleteTemplateButtonLoading = true;
                const response = await this.deleteTemplate({
                    categoryId: this.$route.params.id,
                    id: this.selectedTemplate.id
                });

                if (response && response.data && response.data.status === 'OK' && response.data.result === 'success') {
                    notificationSuccess('Template deleted successfully');
                    this.tableLoading = true;
                    await this.setAdditionalQuestionTemplateData();
                    await window.UIkit.modal('#confirmation-delete-template-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.deleteTemplateButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async createOrEditAdditionalQuestionTemplate() {
            try {
                if (this.modalBoxType === 'create') {
                    await this.createAdditionalQuestionTemplate();
                } else if (this.modalBoxType === 'edit') {
                    await this.editAdditionalQuestionTemplate();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCreateOrEditTemplateModalBox({ type, id, name, questions }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedTemplate();
                this.modalBoxType = type;

                if (type === 'edit') {
                    await this.setAdditionalQuestionTemplateData();
                    this.modalBoxTitle = 'Edit Question(s) Template';
                    this.selectedTemplate.id = id;
                    this.selectedTemplate.name = name;
                    this.selectedTemplate.questions = questions;

                } else {
                    this.modalBoxTitle = 'Create Question(s) Template';
                }

                await window.UIkit.modal('#create-or-edit-template-modal-box').show();
                const textareaSelector = document.querySelectorAll('textarea[name="description"]');
                textareaSelector.forEach(this.descriptionChange);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationDeleteTemplateModalBox({ id, name }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedTemplate();

                this.selectedTemplate.id = id;
                this.selectedTemplate.name = name;

                await window.UIkit.modal('#confirmation-delete-template-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        addQuestion() {
            this.selectedTemplate.questions.push({
                type: 1,
                question: '',
                description: null,
                options: [],
                required: false
            });
        },
        deleteQuestion(indexQuestion) {
            this.selectedTemplate.questions.splice(indexQuestion, 1);
        },
        addOptions(indexQuestion) {
            this.selectedTemplate.questions[indexQuestion].options.push('');
        },
        deleteOptions(indexQuestion, indexOption) {
            this.selectedTemplate.questions[indexQuestion].options.splice(indexOption, 1);
        },
        validateQuestion(question, indexQuestion) {
            if (!question.question || question.question == '') {
                this.questionErrors[indexQuestion] = { question: 'The question is required' };
            }

            if ([this.questionType.single_choice, this.questionType.multi_choices].includes(question.type)) {
                if (question.options.length <= 0) {
                    this.questionErrors[indexQuestion] = { ...this.questionErrors[indexQuestion], options: 'You have to add at least one option' };
                } else {
                    for (let i = 0; i < question.options.length; i++) {
                        if (!question.options[i] || question.options[i] == '') {
                            this.questionErrors[indexQuestion] = { ...this.questionErrors[indexQuestion], options: `The option ${i + 1} is required` };
                            break;
                        }
                    }
                }
            }
        },
        resetSelectedTemplate() {
            if (!navigator.onLine) return this.internetConnection = false;
            this.selectedTemplate.id = '';
            this.selectedTemplate.name = '';
            this.selectedTemplate.questions = [];
            this.$validator.reset();
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', { delayHide: 0 });
            closeDropdown ? closeDropdown.hide() : null;
        },
        goBack() {
            this.$router.back();
        },
        isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        descriptionChange(e, index) {
            if (
                (index !== undefined && !e) && // from mounted modal
                !e.target // from input handler on textarea
            ) {
                return ;
            }

            const element = e.target || e;
            element.style.height = '40px';
            if (element.scrollHeight > element.clientHeight) {
                element.style.height = `${element.scrollHeight}px`;
            }
        }
    }
};
</script>

<style scoped>
.add-question-button {
    width: 100%;
    font-size: 16px;
    color: #0275D8;
    border: 1px solid #0275D8;
}

.delete-question-button {
    background: transparent;
    border: none;
    font-size: 14px;
    color: #D8020F;
}

.delete-option-button {
    background: white;
    border: none;
    cursor: pointer;
}

.delete-option-button-icon {
    width: 100%;
    height: 100%;
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 18px;
}

.add-option-button {
    background: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 25px;
    color: #0275D8;
    padding: 0px;
    margin: 0px;
}

textarea[name="description"] {
    resize: vertical;
    height: 40px;
}
</style>
