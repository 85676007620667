<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">{{ formTitle }}</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Document name <b class="uk-text-danger">*</b></label>
                        <input class="uk-input" :class="{ 'uk-form-danger': errors.has('name') }" name="name" type="text"
                            :maxlength="20" v-model="form.name" v-validate="'required|max:20'">
                        <div uk-grid>
                            <div class="uk-inline uk-width-2-3">
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{
                                    errors.first('name') }}</span>
                            </div>
                            <div class="uk-width-1-3 uk-text-right">
                                <span class="uk-text-small">{{ form.name.length }}/20 Characters</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <section v-if="isLoading">
                        <button class="uk-button uk-button-primary" type="button" disabled>
                            <div uk-spinner></div>
                        </button>
                    </section>
                    <section v-else>
                        <button class="uk-button uk-button-default uk-border-rounded uk-margin-right uk-text-primary"
                            type="button" style="border-color: #0f7ae5;" @click="hideModal(false)">
                            Cancel
                        </button>
                        <button class="uk-button uk-border-rounded" type="button"
                            :class="[disableSaveButton ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="disableSaveButton" @click="sendForm">
                            Save
                        </button>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            form: this.$props.data,
            isLoading: false,
        };
    },
    computed: {
        isCreate() {
            return !this.form._id;
        },
        formTitle() {
            return this.isCreate ? 'Create Additional Document Type' : 'Edit Additional Document Type';
        },
        formName() {
            return this.isCreate ? this.$props.data?.name : '';
        },
        disableSaveButton() {
            let result = true;

            if (
                (this.isCreate && this.form.name !== '' || !this.isCreate) && 
                (this.form.name.length <= 20 && !this.$validator.errors.any())
            ) result = false;

            return result;
        }
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        ...mapActions({
            patchAdditionalDocument: 'other_document/patchAdditionalDocument',
            createAdditionalDocument: 'other_document/createAdditionalDocument',
        }),
        hideModal(isRefresh) {
            this.resetForm();
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;
                if (!this.form._id) {
                    delete this.form._id;
                }

                const response = this.form._id
                    ? await this.patchAdditionalDocument({ id: this.form._id, formData: { name: this.form.name } })
                    : await this.createAdditionalDocument(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.hideModal(true);
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                this.isLoading = false;
                notificationDanger(err);
            }
        },
        resetForm() {
            this.form.name = '';
            this.errors.clear();
            this.$validator.reset();
        },
    }
};
</script>
