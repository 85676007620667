<template>
    <div
        v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])"
        class="uk-container uk-container-expand uk-margin-top card-scrollable"
    >
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" v-model="keywords" @keyup.enter="changeMeta('name')" type="text" placeholder="Search Document">
                </div>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal(null)">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>No</th>
                            <th>Document's Name</th>
                            <th class="uk-width-auto uk-text-right">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="7"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td><div>{{ i + 1 }}</div></td>
                            <td><div class="one-line-text">{{ data.name }}</div></td>
                            <td class="uk-text-right">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <template>
                                            <li><a @click="showModal(data)">Edit</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showModalDelete(data)">Delete</a></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="5"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
                :limit="meta.limit"
            />
        </div>
        <CreateModal v-if="showCreateModal" :data="form" @hideModal="hideModal" />
        <ConfirmDeleteModal v-if="deleteData.isShow" :data="deleteData" @hideModal="hideModal" @confirmDelete="confirmDelete" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import CreateModal from './create';
import ConfirmDeleteModal from '@/components/globals/modals/confirm_delete';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data(){
        return {
            user_cred: getUserLogin(),
            meta: {
                limit: 10,
                page: 1,
                name: this.$route.query.keywords ?? null
            },
            form: {
                _id: null,
                name: '',
            },
            keywords: this.$route.query.keywords ?? null,
            page_data: { docs: [], totalDocs: 0 },
            isLoading: true,
            isEdit: false,
            showCreateModal: false,
            formatter,
            deleteData: {
                isShow: false,
                title: 'Are you sure to delete this additional document?',
                _id: null,
                body: {
                    "Document's Name": null,
                }
            }
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        CreateModal,
        ConfirmDeleteModal,
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getAdditionalDocument: 'other_document/getAdditionalDocument',
            deleteAdditionalDocument: 'other_document/deleteAdditionalDocument',
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getAdditionalDocument(this.meta);
            this.isLoading = false;
        },
        changeMeta(prop) {
            if (this.keywords) {
                this.$router.push(`additional-question?keywords=${this.keywords}`);
            } else {
                this.$router.push(`additional-question`);
            }
            this.meta = {
                ...this.meta,
                [prop]: this.keywords
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: parseInt(e.target.value)
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        resetForm() {
            this.form = {
                _id: null,
                name: '',
            };
        },
        showModal(data){
            if (data) {
                this.form._id = data._id;
                this.form.name = data.name;
            }
            
            this.showCreateModal = true;
        },
        showModalDelete(data){
            this.deleteData._id = data._id;
            this.deleteData.body["Document's Name"] = data.name;
            
            this.deleteData.isShow = true;
        },
        hideModal(isRefresh){
            if (isRefresh) this.getInitPageData();
            
            this.showCreateModal = false;
            this.deleteData.isShow = false;
            this.resetForm();
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async confirmDelete(id) {
            this.isLoading = true;
            const response = await this.deleteAdditionalDocument(id);
            if (response && response.status === 'OK') {
                notificationSuccess('deleted!');
                this.hideModal(true);
            } else {
                this.isLoading = false;
                notificationDanger('Failed to delete!');
            }
        },
    },
};
</script>

<style scoped>
.one-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}
</style>
