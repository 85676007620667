<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <LoaderPage v-if="isLoading" />
            <template v-else>
                <!-- Create categories button -->
                <div 
                    v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])" 
                    class="uk-flex uk-flex-middle uk-flex-right"
                >
                    <button 
                        type="button" 
                        class="uk-button uk-button-primary uk-border-rounded" 
                        @click="showCreateOrEditAdditionalQuestionCategory({ type: 'create', id: null, name: null })"
                    >Create New Category</button>
                </div>
                <!-- End create categories button -->

                <!-- Category list -->
                <div v-if="additionalQuestionsCategoryData.totalDocs > 0" class="uk-margin-top uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-center uk-table-shrink">No</th>
                                    <th class="uk-text-left uk-text-nowrap uk-width-1-4">Category</th>
                                    <th class="uk-text-left uk-text-nowrap uk-width-expand">Number of Template</th>
                                    <th 
                                        v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])" 
                                        class="uk-text-center uk-text-nowrap uk-width-small"
                                    >Actions</th>
                                </tr>
                            </thead>
                            <LoadingTable v-if="tableLoading" :colspan="4" />
                            <template v-else>
                                <tbody v-if="additionalQuestionsCategoryData.totalDocs > 0">
                                    <tr v-for="(category, index) in additionalQuestionsCategoryData.docs" :key="category && category._id || index">
                                        <td class="uk-text-center uk-table-shrink">
                                            {{ autoIncrementNumberPagination({ 
                                                pagingCounter: additionalQuestionsCategoryData.pagingCounter, 
                                                index 
                                            }) }}
                                        </td>
                                        <td class="uk-text-left uk-text-nowrap uk-width-1-4">{{ category && category.name || '-' }}</td>
                                        <td class="uk-text-left uk-text-nowrap uk-width-expand">
                                            {{ category && category.total_template || 0 }}
                                        </td>
                                        <td 
                                            v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])" 
                                            class="uk-text-center uk-text-nowrap uk-width-small"
                                        >
                                            <button type="button" class="uk-button uk-button-default uk-button-small">Actions</button>
                                            <div uk-dropdown="mode: click">
                                                <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                    <li @click="goToAdditionalQuestionCategory({ id: category && category._id || null })"><a disabled>Detail</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li @click="showCreateOrEditAdditionalQuestionCategory({ 
                                                        type: 'edit', 
                                                        id: category && category._id || null,
                                                        name: category && category.name || null
                                                    })"><a disabled>Edit</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li @click="showConfirmationDeleteAdditionQuestionCategory({ 
                                                        id: category && category._id || null,
                                                        name: category && category.name || null, 
                                                        totalTemplate: category && category.total_template || 0 
                                                    })"><a disabled>Delete</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <EmptyTable v-else :colspan="4" />
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="additionalQuestionsCategoryData.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <EmptyData v-else class="uk-margin-top" />
                <!-- End category list -->

                <!-- Create or edit additional question category modal box -->
                <div 
                    id="create-or-edit-additional-question-category-modal-box" 
                    class="uk-flex-top create-or-edit-additional-question-category-modal-box" 
                    uk-modal 
                    esc-close="false" 
                    bg-close="false"
                >
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">{{ modalBoxTitle }}</h2>
                        </div>
                        <form @submit.prevent="createOrEditAdditionalQuestionCategory">
                            <div class="uk-modal-body">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        <label for="categoryName" class="uk-form-label">Category Name <span class="uk-text-danger">*</span></label>
                                        <div class="margin-top-5px">
                                            <input 
                                                id="categoryName" 
                                                type="text"
                                                class="uk-input uk-border-rounded"
                                                maxlength="60"
                                                placeholder="Type category name here..."
                                                data-vv-name="categoryName"
                                                data-vv-scope="selectedAdditionalQuestionCategory"
                                                v-validate="{ 
                                                    required: true,
                                                    max: 60
                                                }"
                                                v-model="selectedAdditionalQuestionCategory.name"
                                            />
                                            <div 
                                                class="uk-width-1-1 uk-flex uk-flex-middle"
                                                :class="[errors.has('selectedAdditionalQuestionCategory.categoryName') ? 'uk-flex-between' : 'uk-flex-right']"
                                            >
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedAdditionalQuestionCategory.categoryName')">
                                                    {{ errors.first('selectedAdditionalQuestionCategory.categoryName') }}
                                                </span>
                                                <p class="uk-text-small uk-margin-remove uk-text-right">{{ selectedAdditionalQuestionCategory.name.length }}/60</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-right uk-flex-middle">
                                <LoaderButton v-if="createOrEditAdditionalQuestionsButtonLoading" />
                                <button 
                                    v-else
                                    type="submit" 
                                    :disabled="disabledButtonCreateOrEditAdditionalQuestionCategory"
                                    :class="[disabledButtonCreateOrEditAdditionalQuestionCategory ? 'uk-button-default cursor-not-allowed' : 'uk-button-primary cursor-pointer']"
                                    class="uk-button"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End create or edit additional question category modal box -->

                <!-- Confirmation delete additional question category modal box -->
                <div 
                    id="confirmation-delete-additional-question-category-modal-box" 
                    class="uk-flex-top confirmation-delete-additional-question-category-modal-box" 
                    uk-modal 
                    esc-close="false" 
                    bg-close="false"
                >
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <div class="uk-width-1-1 uk-flex uk-flex-middle uk-flex-left">
                                <img 
                                    :src="`${images}/error-40-outline.svg`"
                                    alt=""
                                    loading="lazy"
                                    class="uk-margin-small-right"
                                />
                                <h2 class="uk-modal-title uk-margin-remove">Delete Category</h2>
                            </div>
                        </div>
                        <div class="uk-modal-body">
                            <p 
                                v-if="selectedAdditionalQuestionCategory.totalTemplate < 1" 
                                class="uk-margin-remove"
                            >Are you sure you want to delete this <span class="uk-text-bold">'{{ selectedAdditionalQuestionCategory.name || '-' }}'</span> category?</p>
                            <p v-else class="uk-margin-remove">Sorry, you can't delete this category as it contains created question templates.</p>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <template v-if="selectedAdditionalQuestionCategory.totalTemplate < 1">
                                <LoaderButton v-if="deleteAdditionalQuestionButtonLoading" />
                                <button 
                                    v-else
                                    type="button" 
                                    class="uk-button uk-button-primary"
                                    @click="deleteAdditionalQuestionCategory"
                                >Yes, I'm Sure</button>
                            </template>
                            <button 
                                v-else
                                type="button" 
                                class="uk-button uk-button-primary"
                                @click="closeConfirmationDeleteAdditionQuestionCategory"
                            >OK</button>
                        </div>
                    </div>
                </div>
                <!-- End confirmation delete additional question category modal box -->
            </template>
        </template>
        <NoInternetConnection v-else />
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    // notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { mapActions } from 'vuex';
import formatter from '@/utils/formatter';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'AdditionalQuestionsCategoryList',
    components: {
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        EmptyData: () => import('@/components/globals/AdditionalQuestions/EmptyData'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            createOrEditAdditionalQuestionsButtonLoading: false,
            deleteAdditionalQuestionButtonLoading: false,
            additionalQuestionsCategoryData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0,
            },
            meta: {
                limit: 10,
                page: 1,
            },
            selectedAdditionalQuestionCategory: {
                id: '',
                name: '',
                totalTemplate: 0
            },
            modalBoxType: 'create',
            modalBoxTitle: 'Create New Category',
        };
    },
    watch: {
        async meta() {
            try {
                this.tableLoading = true;
                await this.setAdditionalQuestionCategoryData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        }
    },
    computed: {
        disabledButtonCreateOrEditAdditionalQuestionCategory() {
            let disabled = true;

            if (this.modalBoxType === 'create') {
                if (
                    this.selectedAdditionalQuestionCategory.name === ''
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.modalBoxType === 'edit') {
                if (
                    this.selectedAdditionalQuestionCategory.id === '' ||
                    this.selectedAdditionalQuestionCategory.name === ''
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else {
                disabled = true;
            }

            return disabled;
        },
        user_cred() {
            return getUserLogin();
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-additional-question-category-modal-box')) window.UIkit.modal('.create-or-edit-additional-question-category-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-additional-question-category-modal-box')) window.UIkit.modal('.confirmation-delete-additional-question-category-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.setAdditionalQuestionCategoryData();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            createTemplateCategory: 'additionalQuestionTemplateCategoryV4/createTemplateCategory',
            getTemplateCategoryListPaginate: 'additionalQuestionTemplateCategoryV4/getTemplateCategoryListPaginate',
            editTemplateCategory: 'additionalQuestionTemplateCategoryV4/editTemplateCategory',
            deleteTemplateCategory: 'additionalQuestionTemplateCategoryV4/deleteTemplateCategory',
        }),
        async setAdditionalQuestionCategoryData() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;

                const response = await this.getTemplateCategoryListPaginate(this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;
                    const docs = result.docs || [];
                    const totalDocs = result.totalDocs || 0;
                    const pagingCounter = result.pagingCounter || 0;

                    this.additionalQuestionsCategoryData.docs = docs;
                    this.additionalQuestionsCategoryData.totalDocs = totalDocs;
                    this.additionalQuestionsCategoryData.pagingCounter = pagingCounter;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createAdditionalQuestionCategory() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
            
                const validate = await this.$validator.validate('selectedAdditionalQuestionCategory.*');
                if (!validate || this.$validator.errors.any('selectedAdditionalQuestionCategory')) return;

                this.createOrEditAdditionalQuestionsButtonLoading = true;

                const payload = {
                    name: ''
                };

                if (this.selectedAdditionalQuestionCategory.name) {
                    payload.name = this.selectedAdditionalQuestionCategory.name;
                } else {
                    payload.name = '';
                }

                const response = await this.createTemplateCategory(payload);
                if (response && response.data.status === 'OK' && response.data.result && response.data.result._id) {
                    notificationSuccess('Category created successfully');
                    this.tableLoading = true;
                    await this.setAdditionalQuestionCategoryData();
                    await window.UIkit.modal('#create-or-edit-additional-question-category-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditAdditionalQuestionsButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async editAdditionalQuestionCategory() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;

                const validate = await this.$validator.validate('selectedAdditionalQuestionCategory.*');
                if (!validate || this.$validator.errors.any('selectedAdditionalQuestionCategory')) return;

                this.createOrEditAdditionalQuestionsButtonLoading = true;

                const payload = {
                    id: '',
                    name: ''
                };

                if (this.selectedAdditionalQuestionCategory.id) {
                    payload.id = this.selectedAdditionalQuestionCategory.id;
                } else {
                    payload.id = '';
                }

                if (this.selectedAdditionalQuestionCategory.name) {
                    payload.name = this.selectedAdditionalQuestionCategory.name;
                } else {
                    payload.name = '';
                }

                const response = await this.editTemplateCategory(payload);
                if (response && response.data && response.data.status === 'OK' && response.data.result && response.data.result._id) {
                    notificationSuccess('Category updated successfully');
                    this.tableLoading = true;
                    await this.setAdditionalQuestionCategoryData();
                    await window.UIkit.modal('#create-or-edit-additional-question-category-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);    
            } finally {
                this.createOrEditAdditionalQuestionsButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async createOrEditAdditionalQuestionCategory() {
            try {
                if (this.modalBoxType === 'create') {
                    await this.createAdditionalQuestionCategory();
                } else if (this.modalBoxType === 'edit') {
                    await this.editAdditionalQuestionCategory();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async deleteAdditionalQuestionCategory() {
            try {
                this.deleteAdditionalQuestionButtonLoading = true;

                const response = await this.deleteTemplateCategory(this.selectedAdditionalQuestionCategory.id);
                if (response && response.data && response.data.status === 'OK' && response.data.result === 'success') {
                    notificationSuccess('Category deleted successfully');
                    this.tableLoading = true;
                    await this.setAdditionalQuestionCategoryData();
                    await window.UIkit.modal('#confirmation-delete-additional-question-category-modal-box').hide();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.deleteAdditionalQuestionButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async showCreateOrEditAdditionalQuestionCategory({ type, id, name }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedAdditionalQuestionCategory();
                this.modalBoxType = type;
                if (type === 'edit') {
                    this.modalBoxTitle = 'Edit Category Name';
                    this.selectedAdditionalQuestionCategory.id = id;
                    this.selectedAdditionalQuestionCategory.name = name;
                } else {
                    this.modalBoxTitle = 'Create New Category';
                }
                await window.UIkit.modal('#create-or-edit-additional-question-category-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationDeleteAdditionQuestionCategory({ id, name, totalTemplate }) {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.hideDropdown();
                this.resetSelectedAdditionalQuestionCategory();

                this.selectedAdditionalQuestionCategory.id = id;
                this.selectedAdditionalQuestionCategory.name = name;
                this.selectedAdditionalQuestionCategory.totalTemplate = totalTemplate;

                await window.UIkit.modal('#confirmation-delete-additional-question-category-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async closeConfirmationDeleteAdditionQuestionCategory() {
            try {
                await window.UIkit.modal('#confirmation-delete-additional-question-category-modal-box').hide();
                this.hideDropdown();
                this.resetSelectedAdditionalQuestionCategory();
            } catch (error) {
                notificationDanger(error);
            }
        },
        goToAdditionalQuestionCategory({ id }) {
            this.$router.push(`/admin/additional-questions/${id}`);
        },
        resetSelectedAdditionalQuestionCategory() {
            if (!navigator.onLine) return this.internetConnection = false;

            this.selectedAdditionalQuestionCategory.id = '';
            this.selectedAdditionalQuestionCategory.name = '';
            this.selectedAdditionalQuestionCategory.totalTemplate = 0;
            this.$validator.reset();
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', { delayHide: 0 });
            closeDropdown ? closeDropdown.hide() : null;
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
.margin-top-5-px {
    margin-top: 5px;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
